import Vue from 'vue';
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate);
import {validationMixin} from 'vuelidate';
import {required, email, maxLength} from 'vuelidate/lib/validators';

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'restore-password-email',
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        email: ''
      },
      validationErrors: {
        email: []
      }
    }
  },
  validations: {
    payload: {
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      restoreLoading: `auth/restoreLoading`,
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    }
  },
  methods: {
    ...mapActions({
      send: `auth/RESTORE_PASSWORD`
    }),
    ...mapMutations({
      changeShowPopupRestore: `auth/CHANGE_POPUP_RESTORE_PASSWORD`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.send(this.payload)
          .then(resp => {
            this.changeShowPopupRestore(false);
            this.changeContentPopup({
              text: 'Вам на электронную почту было отправлено письмо, чтоб изменить пароль, следуйте инструкции в письме.'
            });
            setTimeout(()=> {
              this.changeShowContentPopup(true);
            },250)
          })
          .catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }

            formattedMessage && this.$toasted.error(formattedMessage);
          });
      }
    }
  },
  destroyed() {

  }
}
